import { Injectable } from '@angular/core';
import {
  MixpanelEvent,
  MixpanelEventName,
  MixpanelEventTriggerElement,
  MixpanelEventTriggerLabel,
  MixpanelEventType,
  UniAnalyticsService,
} from '@unifonic/common';

enum INTMixpanelEventName {
  ConnectorClick = 'Connector clicked',
}

enum INTMixpanelEventTriggerLabel {}

enum INTMixpanelEventTriggerElement {
  ConnectorName = 'connector_name',
}

interface INTMixpanelEventTrigger {
  name: INTMixpanelEventTriggerLabel | MixpanelEventTriggerLabel | string;
  type: INTMixpanelEventTriggerElement | MixpanelEventTriggerElement;
}

interface INTMixpanelEvent {
  name: INTMixpanelEventName | MixpanelEventName;
  type: MixpanelEventType;
  trigger: INTMixpanelEventTrigger;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private uniAnalyticsService: UniAnalyticsService) {}

  trackClickOnConnector(connectorName: string) {
    this.track({
      name: INTMixpanelEventName.ConnectorClick,
      type: MixpanelEventType.click,
      trigger: {
        name: connectorName,
        type: INTMixpanelEventTriggerElement.ConnectorName,
      },
    });
  }

  trackClickCreateNewConnection() {
    this.track({
      name: MixpanelEventName.createIntegrationConnection,
      type: MixpanelEventType.click,
      trigger: {
        name: MixpanelEventTriggerLabel.createNewConnection,
        type: MixpanelEventTriggerElement.button,
      },
    });
  }

  private track(event: INTMixpanelEvent) {
    this.uniAnalyticsService.track(event as MixpanelEvent);
  }
}
