import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CategoryState } from '../store/category.state';
import { CategorySelectors } from '../store/category.selectors';
import { CategoryActions } from '../store/category.actions';
import { CreateCategoryRequest, GetCategoryResponse, UpdateCategoryRequest } from './category.model';
import { Observable } from 'rxjs';
import { CategoryRepository } from './category.repository';

@Injectable({ providedIn: 'root' })
export class CategoryFacade {
  categories$ = this.store.pipe(select(CategorySelectors.selectCategory));

  constructor(private store: Store<CategoryState>, private categoryRepository: CategoryRepository) {}

  setCategories(): void {
    this.store.dispatch(new CategoryActions.SetCategory());
  }

  createCategory(category: CreateCategoryRequest): Observable<GetCategoryResponse> {
    return this.categoryRepository.createCategory(category);
  }

  updateCategory(category: UpdateCategoryRequest): Observable<GetCategoryResponse> {
    return this.categoryRepository.updateCategory(category);
  }

  deleteConnection(categoryId: string) {
    this.store.dispatch(new CategoryActions.DeleteCategory(categoryId));
  }

  getCategoryById(categoryId: string) {
    return this.categoryRepository.getSingleCategory(categoryId);
  }
}
